import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { AvatarModel } from 'core/data/domain/avatar.model';
import { AvatarRepository } from 'core/data/repository/avatar/avatar.repository';
import { UserInfoResponse } from 'core/fapi/models';
import { Observable, filter, map, switchMap, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fid-profile-avatar',
  standalone: true,
  imports: [NgIf, NgOptimizedImage, AsyncPipe, CdnUrlPipe],
  templateUrl: './profile-avatar.component.html',
  styleUrl: './profile-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileAvatarComponent {
  private readonly avatarsRepository = inject(AvatarRepository);

  private readonly codeSubject$ = new BehaviorSubject<UserInfoResponse['avatar'] | null>(null);

  @Input({ required: true }) public set code(value: UserInfoResponse['avatar']) {
    this.codeSubject$.next(value);
  }
  @Input() @HostBinding('attr.data-radius') public radius: 'rounded' | 'full' = 'rounded';
  @Input() @HostBinding('attr.data-size') public size: 'small' | 'large' | 'auto' = 'small';
  @Input() public withBackground: boolean = false;

  public readonly avatar$: Observable<AvatarModel> = this.codeSubject$.asObservable().pipe(
    filter(Boolean),
    map(parseInt),
    filter(Boolean),
    switchMap(avatar => this.avatarsRepository.getAvatar(avatar)),
  );
}
